import store from "@/store";

import { createRouter, createWebHistory } from "vue-router";
import seoTranslations from "@/translations/seo/seoTranslations.json";

const baseRoutes = [
  {
    path: "/",
    redirect: { path: "/hello_legenda" }, // redirect property
  },

  {
    path: "/:pathMatch([a-z]{2})", //the two digit language code
    redirect: (to) => {
      // Extract the language from the route parameters
      const { language } = to.params;
      if (Object.keys(seoTranslations).includes(language)) {
        // Redirect to the desired route, passing the language parameter
        return `hello_legenda/${language}`;
      } else {
        // take back to "/" ( -> hello_legenda )
        return "";
      }
    },
  },
  // navbar if authed , general if not
  {
    path: "/hello_legenda/:language?",
    name: "LandingPage",
    components: {
      default: () => import('../views/landingPage/LandingPageView.vue'),
      Navbar: () => import('../components/navbar/GeneralNavBarComponent.vue'),
      Footer: () => import('../views/landingPage/components/FooterComponent.vue')
    },
    props: true,
  },
  {
    path: "/olympiaLex/:token",
    name: "OlympiaLexLogin",
    components: {
      default: () => import('../views/auth/olympiaLex/OlympiaLexLogin.vue'),
      Navbar: () => import('../components/navbar/GeneralNavBarComponent.vue'),
    },
    props: true,
  },
  {
    path: "/login",
    name: "Login",
    components: {
      default: () => import('../views/auth/legenda/LoginView.vue'),
      Navbar: () => import('../components/navbar/GeneralNavBarComponent.vue'),
    },
    meta: { requiresGuest: true },
  },
  {
    path: "/privacy_policy",
    name: "Privacy",
    components: {
      default: () => import('../views/legal/PrivacyView.vue'),
      Navbar: () => getNavBar()
    },
    meta: { theme: "legendaTheme" },
  },
  {
    path: "/terms_conditions",
    name: "TermsConditions",
    components: {
      default: () => import('../views/legal/TermsConditionsView.vue'),
      Navbar: () => getNavBar()
    },
    meta: { theme: "legendaTheme" },
  },
  {
    path: "/contact",
    name: "Contact",
    components: {
      default: () => import('../views/legal/ContactView.vue'),
      Navbar: () => getNavBar()
    },
    meta: { theme: "legendaTheme" },
  },
  {
    path: "/:pathMatch(.*)*",
    components: {
      default: () => import('../views/PageNotFound.vue'),
      Navbar: () => getNavBar()
    },
    name: "PageNotFound",
  },
];

const legendaRoutes = [
  {
    path: "/register/:token",
    name: "Register",
    components: {
      default: () => import('../views/auth/legenda/RegisterView.vue'),
      Navbar: () => import('../components/navbar/GeneralNavBarComponent.vue'),
    },
    props: true,
  },

  {
    path: "/profile",
    name: "Profile",
    components: {
      default: () => import('../views/ProfileView.vue'),
      Navbar: () => import('../components/navbar/AuthNavBarComponent.vue'),
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/pdf_explorer/:folder_id/:doc_metadata_id",
    name: "DocumentExplorer",
    components: {
      default: () => import('../views/document/DocumentExplorer.vue'),
      Navbar: () => import('../components/navbar/AuthNavBarComponent.vue'),
    },
    meta: { requiresAuth: true },
    props: (route) => ({
      folder_id: Number(route.params.folder_id),
      doc_metadata_id: route.params.doc_metadata_id,
    }),
  },
  {
    path: "/search/:search_query_id/search_results/",
    name: "SearchResults",
    components: {
      default: () => import('../views/search/SearchResults.vue'),
      Navbar: () => import('../components/navbar/AuthNavBarComponent.vue'),
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/forgot_password",
    components: {
      default: () => import('../views/auth/legenda/ForgotPasswordView.vue'),
      Navbar: () => import('../components/navbar/GeneralNavBarComponent.vue'),
    },
  },
  {
    path: "/update_password/:token",
    components: {
      default: () => import('../views/auth/legenda/UpdatePassword.vue'),
      Navbar: () => import('../components/navbar/GeneralNavBarComponent.vue'),
    },
    props: true,
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    components: {
      default: () => import('../views/signup/SubscriptionView.vue'),
      Navbar: () => import('../components/navbar/GeneralNavBarComponent.vue'),
    },
  },
  {
    path: "/checkout_confirmation/:sessionId",
    name: "CheckoutConfirmation",
    components: {
      default: () => import('../views/signup/CheckoutView.vue'),
      Navbar: () => import('../components/navbar/GeneralNavBarComponent.vue'),
    },
    props: true,
  },
  {
    path: "/changelog",
    name: "Changelog",
    props: true,
    components: {
      default: () => import('../views/ChangelogView.vue'),
      Navbar: () => getNavBar()
    }
  },
  {
    path: "/guide",
    name: "Guide",
    components: {
      default: () => import('../views/GuideView.vue'),
      Navbar: () => import('../components/navbar/GeneralNavBarComponent.vue'),
    },
  },
];

const legendaDashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    components: {
      default: () => import('../views/dashboard/legenda/DashboardView.vue'),
      Navbar: () => import('../components/navbar/AuthNavBarComponent.vue'),
    },
    meta: {
      requiresAuth: true,
      breadCrumb: [
        {
          title: "Dashboard",
        },
      ],
    },
  },
  {
    path: "/content/:folder_id?",
    name: "Content",
    components: {
      default: () => import('../views/dashboard/legenda/DashboardView.vue'),
      Navbar: () => import('../components/navbar/AuthNavBarComponent.vue'),
    },
    meta: {
      requiresAuth: true,
      meta: {
        breadCrumb(route) {
          const folder_id = route.params.folder_id;
          return [
            {
              title: folder_id,
            },
          ];
        },
      },
    },
    props: (route) => ({ folder_id: Number(route.params.folder_id) }),
  },
  {
    path: "/",
    redirect: { name: "Dashboard" },
  },
];

const olympialexDashboardRoutes = [
  {
    path: "/olympialex/dashboard",
    name: "olympialexDashboard",
    components: {
      default: () => import('../views/dashboard/olympialex/OlympialexDashboardView.vue'),
      Navbar: () => import('../components/navbar/AuthNavBarComponent.vue'),
    },
    meta: {
      requiresAuth: true,
      breadCrumb: [
        {
          title: "olympialexDashboard",
        },
      ],
    },
  },
  {
    path: "/olympialex/content/:folder_id?",
    name: "olympialexContent",
    components: {
      default: () => import('../views/dashboard/olympialex/OlympialexDashboardView.vue'),
      Navbar: () => import('../components/navbar/AuthNavBarComponent.vue'),
    },
    meta: {
      requiresAuth: true,
      meta: {
        breadCrumb(route) {
          const folder_id = route.params.folder_id;
          return [
            {
              title: folder_id,
            },
          ];
        },
      },
    },
    props: (route) => ({ folder_id: Number(route.params.folder_id) }),
  },
  {
    path: "/",
    redirect: { name: "olympialexDashboard" },
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: baseRoutes
    .concat(legendaRoutes)
    .concat(legendaDashboardRoutes)
    .concat(olympialexDashboardRoutes),
  // ...
});
// Navigation Guard: redirect to the /login route if not authenticated
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresGuest = to.matched.some((record) => record.meta.requiresGuest);
  const isUserLoggedIn = store.getters.isAuthenticated;

  const isOlympialexView = store.getters.olympiaLexView;
  const searchScope = store.getters.stateSearchScope;

  const olympialexUserRoutes = legendaRoutes.concat(olympialexDashboardRoutes);
  const legendaUserRoutes = legendaRoutes.concat(legendaDashboardRoutes);
  if (requiresAuth) {
    if (isUserLoggedIn) {
      if (
        // olympialex user routes
        (isOlympialexView &&
          olympialexUserRoutes.map((x) => x.name).includes(to.name)) ||
        //legenda user routes
        (!isOlympialexView &&
          legendaUserRoutes.map((x) => x.name).includes(to.name))
      ) {
        next();
      } else {
        next({ name: "PageNotFound" });
      }
    } else {
      next("/login");
    }
  } else if (requiresGuest) {
    if (isUserLoggedIn) {
      if (isOlympialexView) {
        if (searchScope.length > 0) {
          next(`/olympialex/content/${searchScope[0].itemId}`);
        } else {
          next("/olympialex/dashboard");
        }
      } else {
        if (searchScope.length > 0) {
          next(`/content/${searchScope[0].itemId}`);
        } else {
          next("/dashboard");
        }
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

const getNavBar = () => {
  const isUserLoggedIn = store.getters.isAuthenticated;

  return isUserLoggedIn ?
    import('../components/navbar/AuthNavBarComponent.vue')
    : import('../components/navbar/GeneralNavBarComponent.vue')
}

export default router;

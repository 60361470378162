function getThemes() {
  const legendaTheme = {
    colors: {
      "checkbox-primary": "#3F3442",
      "button-primary": "#F1FF82",
      "button-secondary": "#3F3442",
      "button-secondary-darken-1": "#8ebc6c",
      "butoon-secondary-darken-2": "#7ca45e",
      "button-ternary": "#FCFFE0",
      "button-contrast": "#3F3442",
      "icon-primary": "#3F3442",
      "icon-secondary": "#B2EB87",
      "typo-primary": "#3F3442",
      "typo-secondary": "#F8F5ED",
      "typo-ternary": "#B2EB87",
      "component-primary": "#ffffff",
      "input-primary": "#3F3442",
      "visual-primary": "#B2EB87",
      "table-primary": "#ffffff",
      "modal-primary": "#ffffff",
      "page-primary": "#F5F5F5",
      "page-secondary": "#F5F5F5",
      "toolbar-primary": "#ffffff",
      "footer-primary": "#292929",
      "footer-secondary": "#F8FFC1",

      // neutral will be used as a neutral background, surface is the default color
      //to be shown inside components, eg the color of an input (actual color of input, not the font color in the input)
      neutral: "#F5F5F5",
      "neutral-lighten-1": "#fafafa",
      "neutral-darken-1": "#d3d3d3",
      "neutral-darken-2": "#939393",
      surface: "#ffffff",

      primary: "#F1FF82",
      "primary-lighten-1": "#F5FFA1",
      "primary-lighten-2": "#F8FFC1",
      "primary-lighten-3": "#FCFFE0",
      "primary-lighten-4": "#FEFFF3",
      "primary-darken-1": "#bcc571",
      "primary-darken-2": "#B5BF62",

      secondary: "#B2EB87",
      "secondary-lighten-1": "#C5F0A5",
      "secondary-lighten-2": "#D9F5C3",
      "secondary-lighten-3": "#ECFAE1",
      "secondary-darken-1": "#8ebc6c",
      "secondary-darken-2": "#7ca45e",

      contrast: "#3F3442",
      "contrast-lighten-1": "#78707A",
      "contrast-lighten-2": "#F8F5ED",
      "contrast-lighten-3": "#D8D6D9",

      error: "#FDB0AB",
      info: "#90CAF9",
      success: "#D9F5C3",
      warning: "#FCFFE0",
      "custom-warning": "#F2E7DD",
      border: "#0d47a1",
      danger: "#3F3442",
      dark: "#3F3442",

      // search results
      "search-high": "#B2EB87",
      "search-high-lighten-1": "#ECFAE1",
      "search-high-darken-1": "#597644",

      "search-mid": "#F3B781",
      "search-mid-lighten-1": "#F2E7DD",
      "search-mid-darken-1": "#795717",

      "search-low": "#FDB0AB",
      "search-low-lighten-1": "#FEECEB",
      "search-low-darken-1": "#7F4239",

      // upload progress
      "status-empty": "#808080",
      "status-searchable": "#B2EB87",
      "status-in-progress": "#F3B781",
      "status-in-progress-ocr": "#CFB0FA",
      "status-failed": "#FDB0AB",
      "status-pdf-conversion": "#69D1F5",
    },
  };

  const olympialexTheme = {
    colors: {
      "checkbox-primary": "#3F3442",
      "button-primary": "#425951",
      "button-secondary": "#3F3442",
      "button-ternary": "#FCFFE0",
      "button-contrast": "#092116",
      "icon-primary": "#AC9731",
      "typo-primary": "#3F3442",
      "typo-secondary": "#3b8044",
      "typo-ternary": "#AC9731",
      "component-primary": "#ffffff",
      "input-primary": "#3F3442",
      "visual-primary": "#3b8044",
      "table-primary": "#ffffff",
      "modal-primary": "#ffffff",
      "page-primary": "#F5F5F5",
      "page-secondary": "#F5F5F5",
      "toolbar-primary": "#092116",

      // neutral will be used as a neutral background, surface is the default color
      //to be shown inside components, eg the color of an input (actual color of input, not the font color in the input)
      neutral: "#F5F5F5",
      surface: "#ffffff",

      primary: "#3b8044",
      "primary-lighten-1": "#9dbfa1",
      "primary-lighten-2": "#ebf2ec",
      "primary-darken-1": "#234c28",
      "primary-darken-2": "#234c28",

      secondary: "#425951",
      contrast: "#092116",
      "contrast-lighten-1": "#75736E",
      "contrast-lighten-2": "#B2AFA8",

      error: "#8b0000",
      info: "#90CAF9",
      success: "#D9F5C3",
      warning: "#FCFFE0",
      border: "#0d47a1",
      danger: "#3F3442",
      dark: "#3F3442",

      "search-high": "#689F38",
      "search-high-lighten-1": "#c2d8af",
      "search-high-darken-1": "#5d8f32",

      "search-mid": "#AEEA00",
      "search-mid-lighten-1": "#cef266",
      "search-mid-darken-1": "#9cd200",

      "search-low": "#FFB300",
      "search-low-lighten-1": "#ffd166",
      "search-low-darken-1": "#e5a100",
    },
  };
  return { legendaTheme, olympialexTheme };
}

export { getThemes };

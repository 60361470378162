import axiosWrapper from "../../plugins/axios/axiosWrapper";
import { vuetifyInstance } from "../../plugins/vuetify/vuetify";

const getDefaultUserState = () => {
  return {
    user: null,
    freeSpace: null,
    olympiaLexView: false,
    language: null,
    clickedTab: null,
    llmSearchOption: true,
  };
};

// initial state
const state = getDefaultUserState();

const getters = {
  isAuthenticated: (state) => !!state.user,
  stateUser: (state) => state.user,
  freeSpace: (state) => state.freeSpace,
  olympiaLexView: (state) => state.olympiaLexView,
  language: (state) => state.language,
  clickedTab: (state) => state.clickedTab,
  llmSearchOption: (state) => state.llmSearchOption,
};

const actions = {
  /**
   *
   * @param {*} param0
   * @param {*} token
   */
  // eslint-disable-next-line no-empty-pattern
  async register({ }, { token, user, legal, marketing, profiling, training }) {
    const formData = {
      password: user.password,
      token: token,
      legal: legal,
      marketing: marketing,
      profiling: profiling,
      training: training,
    };

    return await axiosWrapper.postForm("/register", formData);
  },
  /**
   *
   * @param {*} param0
   * @param {*} user
   * @returns
   */
  async logIn({ dispatch, commit }, user) {
    commit("resetState");
    const formData = {
      username: user.username,
      password: user.password,
      client_app_name: 'legenda'
    };

    const loginResponse = await axiosWrapper.postForm("/login", formData);

    if (loginResponse.status == 200) {
      await dispatch("viewMe");
    }

    return this.state.users.user;
  },
  /**
   *
   * @param {*} param0
   */
  async viewMe({ commit }) {
    let { data } = await axiosWrapper.get("/user/whoami");

    commit("setUser", data);
    commit("setOlympiaLexView", data);
    commit(
      "setLlmSearchOption",
      JSON.parse(localStorage.getItem("llmOption")) ?? state.llmSearchOption
    );
  },
  /**
   *
   * @param {*} param0
   */
  // eslint-disable-next-line no-empty-pattern
  async logOut({ }) {
    //reset state
    //reset auth token
    await axiosWrapper.get("/:username/logout", { username: this.state.user });

  },
  /**
   *
   * @param {*} param0
   * @param {*} username
   */
  async getFreeSpace({ commit }, username) {
    const data = await axiosWrapper.get(`/user/:username/free_space`, {
      username: username,
    });

    commit("setFreeSpace", data.data);
  },
  /**
   *
   */
  // eslint-disable-next-line no-empty-pattern
  async preUpdatePassword({ }, user) {
    return await axiosWrapper.post("/pre_update_password", {
      username: user.username,
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} token
   * @returns
   */
  // eslint-disable-next-line no-empty-pattern
  async updatePassword({ }, { token, user }) {
    const payload = {
      password: user.password,
      token: token,
    };

    return await axiosWrapper.post("/update_password", payload);
  },

  async changePassword(
    // eslint-disable-next-line no-empty-pattern
    { },
    { currentPassword, newPassword, confirmNewPassword }
  ) {
    const payload = {
      currentPassword,
      newPassword,
      confirmNewPassword,
    };

    return await axiosWrapper.post("/me/change-password", payload);
  },
  /**
   *
   * @param {*} param0
   * @param {*} user
   * @returns
   */
  // eslint-disable-next-line no-empty-pattern
  async preRegisterUser({ }, user) {
    const payload = {
      username: user.username,
      password: user.password,
    };

    return await axiosWrapper.post("/pre_register", payload);
  },
  /**
   *
   * @param {*} param0
   * @param {*} token
   */
  async olympiaLexLogin({ dispatch, commit }, token) {
    commit("resetState");
    const payload = {
      token: token,
      client_app_name: 'olympialex'
    };

    const loginResponse = await axiosWrapper.post("/ol_login", payload);

    if (loginResponse.status == 200) {
      await dispatch("viewMe");
    }
    return this.state.users.user;
  },
};

const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} user
   */
  setUser(state, user) {
    state.user = user;
  },
  /**
   *
   * @param {*} state
   * @param {*} user
   */
  setOlympiaLexView(state, user) {
    state.olympiaLexView = user.subscription_tier === "olympialex_review";

    if (state.olympiaLexView) {
      vuetifyInstance.theme.global.name.value = "olympialexTheme";
      localStorage.setItem("appTheme", "olympialexTheme");
    }
  },
  /**
   *
   * @param {*} state
   * @param {*} user
   */
  resetUserState(state, user) {
    state.user = user;
  },
  /**
   *
   * @param {*} state
   * @param {*} responseData
   */
  setFreeSpace(state, responseData) {
    state.freeSpace = responseData.free_space;
  },
  /**
   *
   * @param {*} state
   */
  setLanguage(state, language) {
    state.language = language;
  },
  /**
   *
   * @param {*} state
   * @param {*} clickedTab
   */
  setClickedTab(state, clickedTab) {
    state.clickedTab = clickedTab;
  },
  /**
   *
   * @param {*} state
   * @param {*} llmSearchOption
   */
  setLlmSearchOption(state, llmSearchOption) {
    state.llmSearchOption = llmSearchOption;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <v-app>
    <spinner></spinner>
    <router-view name="Navbar"></router-view>
    <v-main class="bg-primary-ligthen-1">
      <router-view :key="$route.fullPath" />
    </v-main>
    <router-view name="Footer"> </router-view>
  </v-app>
</template>

<script>
// @ is an alias to /src
import Spinner from "@/components/ui/Spinner.vue";

export default {
  components: {
    spinner: Spinner,
  },
  data() {
    return {
      credentialPaths: [
        "Dashboard",
        "olympialexDashboard",
        "Profile",
        "Content",
        "olympialexContent",
        "DocumentExplorer",
        "SearchResults",
        "Privacy",
        "TermsConditions",
        "Contact",
        "PageNotFound",
      ],
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated && this.checkContentPath();
    },
  },
  methods: {
    checkContentPath() {
      if (this.$router.currentRoute.value.fullPath == "/") {
        return false;
      }

      const contentPath = this.credentialPaths.filter((element) =>
        this.$router.currentRoute.value.name.includes(element)
      );

      return contentPath.length > 0;
    },
  },
  mounted() {},
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

html,
body,
#app {
  height: 100%;
  margin: 0;
  overflow: auto !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.v-btn {
  text-transform: unset !important;
}
</style>
